/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import styled from "@emotion/styled"
import NotFound from "../../assets/images/svg/not-found.svg"

const NoResultsContainer = styled.h3`
  ${({ theme }) => css`
    color: ${theme.colors.mediumGrey};
    display: block;
    text-align: center;
    width: 100%;
    font-size: 30px;

    svg {
      display: block;
      margin: 0 auto;
      max-width: 100px;
      height: 120px;
    }
  `}
`

const NoResults = ({ className, translation }) => (
  <NoResultsContainer className={className}>
    <NotFound role="img" /> {translation.noResults}
  </NoResultsContainer>
)

export default NoResults
