/** @jsx jsx */
import {connect} from "react-redux"
import {jsx, css} from "@emotion/core"
import styled from "@emotion/styled"
import Trash from "../../../../assets/images/trash.png"
import Print from "../../../../assets/images/print.png"
import {formatMoney} from "../../../../helpers"

const TableContainer = styled.div`
  ${({theme}) => css`
    display: block;
    overflow: auto;

    ${theme.queries.small} {
      margin-left: -15px;
      margin-right: -47px;
    }
  `}
`

const Table = styled.table`
  width: 100%;
  display: table;
  min-width: 992px;
`

const Thead = styled.thead`
  ${({theme}) => css`
    background-color: white;
    width: 100%;
  `}
`

const Th = styled.th`
  ${({theme}) => css`
    text-align: left;
    padding: 20px 15px;
    color: ${theme.colors.blue};
    font-weight: bold;

    &:first-of-type {
      padding-left: 47px;
    }

    &:last-child {
      padding-right: 47px;
    }
  `}
`

const Tr = styled.tr`
  ${({theme}) => css`
    &:nth-of-type(odd) {
      background-color: ${theme.colors.mediumBlue};
    }
  `}
`

const Td = styled.td`
  ${({theme}) => css`
    color: ${theme.colors.mediumGrey};
    padding: 0 15px;
    height: 56px;
    vertical-align: middle;

    &:first-of-type {
      padding-left: 47px;
    }

    &:last-child {
      padding-right: 47px;
    }
  `}
`

const Btn = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  float: right;
`

const Blank = styled.span`
  display: block;
  text-align: center;
`

const TableComponent = ({options, data, unmaintain, cancel, print}) => {
    const optsArray = Object.entries(options)
    return (
        <TableContainer>
            <Table>
                <Thead>
                    <tr>
                        {optsArray.map((item, index) => (
                            <Th key={index}>{item[1]}</Th>
                        ))}
                        <Th></Th>
                    </tr>
                </Thead>

                <tbody>
                {data.map(item => (
                    <Tr>
                        {optsArray.map((opt, index) => {
                            let value = item[opt[0]]
                            if (opt[0] === "date") {
                                var date = new Date(value)
                                value = `${
                                    date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
                                }/${
                                    date.getMonth() + 1 < 10
                                        ? `0${date.getMonth() + 1}`
                                        : date.getMonth() + 1
                                }/${date.getFullYear()} às ${date.getHours()}:${
                                    date.getMinutes() < 10
                                        ? `0${date.getMinutes()}`
                                        : date.getMinutes()
                                }`
                            } else if (opt[0] === "value") {
                                value = `R$ ${formatMoney(value)}`
                            } else if (opt[0] === "isApproved") {
                                value = `${value ? "Pago" : "Pendente"}`
                            }

                            return <Td key={index}>{value || <Blank>-</Blank>}</Td>
                        })}
                        {unmaintain && (
                            <Td>
                                <Btn
                                    onClick={() => {
                                        cancel(item.code)
                                    }}
                                >
                                    <img src={Trash}/>
                                </Btn>
                            </Td>
                        )}
                        {!unmaintain && (
                            <Td>
                                {item.isApproved && (
                                <Btn
                                    onClick={() => {
                                        print(item.type, item)
                                    }}
                                >
                                    <img src={Print} alt={`print`}/>
                                </Btn>
                                )}
                            </Td>
                        )}
                    </Tr>
                ))}
                </tbody>
            </Table>
        </TableContainer>
    )
}

export default connect()(TableComponent)
